@import "./style/colors";

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  color: inherit;

}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: initial !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: initial !important;
}


.blockSlide {

  margin: 0 30px;



  .slick-slide {
    >div {
      max-width: 760px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}

#root {
  overflow: hidden;
}

html {
  position: relative;
  // height: 100vh;
}

.App {
  overflow: hidden;
  // position: relative;
  // overflow: auto;
}



body {
  height: 100vh;
  margin: 0;
  position: relative;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: #1a1a1a;
}

img {
  max-width: inherit;
  height: 100%;
  border: 0;
  vertical-align: middle;
  width: 100%;
  object-fit: cover;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;

  &.active {
    color: $yellow !important;
  }
}

.containerBlock {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  z-index: 2;

  @media screen and (max-width:1000px) {
    margin: 0 15px auto;
  }
}